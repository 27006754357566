import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../../../helpers/Utils";
import ConsumptionModal from "./consumptionModal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../../plans/trialModal";
const ConsumptionList = (props) => {
    const [tax, setTax] = useState(props.data);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [err_dlg, seterr_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [taxModal, setTaxModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !== 1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id == null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const editTax = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsOpen(true);
            setTaxModal(true);
        }
    };
    const deleteTax = (e) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/cosumption-taxes/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setdynamic_description(res.data.message);
                    setsuccess_dlg(true);
                    setdynamic_title("Deleted");
                    setTax(null);
                } else {
                    setdynamic_description(res.data.message);
                    seterr_dlg(true);
                    setdynamic_title("Failed");
                }
            });
    };
    if (tax === null) {
        return "";
    } else
        return (
            <>
                {isOpen ? (
                    <ConsumptionModal
                        taxModal={taxModal}
                        setTaxModal={setTaxModal}
                        tax={tax}
                        setTax={setTax}
                        getTaxes={props.getTaxes}
                    />
                ) : null}
                <CreateTrialModal
                    isTrialModal={isTrialModal}
                    setIsTrialModal={setIsTrialModal}
                    nonActive={nonActive}
                />
                <tr>
                    <td
                        role="button"
                        onClick={
                            getPermission().taxes.taxes.edit.is_checked === 1
                                ? () => editTax()
                                : ""
                        }
                    >
                        {tax.primary_name}
                    </td>
                    <td
                        role="button"
                        onClick={
                            getPermission().taxes.taxes.edit.is_checked === 1
                                ? () => editTax()
                                : ""
                        }
                    >
                        {tax.tax}
                    </td>
                    <td
                        role="button"
                        onClick={
                            getPermission().taxes.taxes.edit.is_checked === 1
                                ? () => editTax()
                                : ""
                        }
                    >
                        {tax.by_default == "1" ? (
                            <i className="fa fa-check" />
                        ) : (
                            <i className="fa fa-times" />
                        )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {getPermission().taxes.taxes.delete.is_checked === 1 ? (
                        <td>
                            <Button
                                color="danger"
                                onClick={() => {
                                    setconfirm_alert(true);
                                }}
                            >
                                {props.t("Delete")}
                            </Button>
                        </td>
                    ) : (
                        ""
                    )}
                </tr>
                {confirm_alert ? (
                    <SweetAlert
                        title={props.t("Are you sure?")}
                        cancelBtnText={props.t("Cancel")}
                        confirmBtnText={props.t("Yes, delete it!")}
                        warning
                        showCancel

                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            deleteTax(tax.id);
                            setconfirm_alert(false);
                        }}
                        onCancel={() => setconfirm_alert(false)}
                    ></SweetAlert>
                ) : null}

                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            setsuccess_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
                {err_dlg ? (
                    <SweetAlert
                        error
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            seterr_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
            </>
        );
};
export default withTranslation()(ConsumptionList);
ConsumptionList.propTypes = {
    t: PropTypes.any,
};
