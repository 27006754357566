import React, { useState, useEffect } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Alert, Table, Modal } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser } from "../../../helpers/Utils";
import Loader from "../../../components/Loader";
import SingleStockData from "../../../components/Product/Stock/stockAll";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function Wharehouse(props) {
    const [proID, setProID] = useState(props.product.id);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [stock, setStock] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [wharehouseStock, setWharehouseStock] = useState("");
    const [wharehouseminStock, setWharehouseminStock] = useState("");
    const [wharehouseLocation, setWharehouseLocation] = useState("");
    const [isOpenModalStock, setIsOpenModalStock] = useState(false);
    const [stockVal, setStockVal] = useState("");
    //   const [selectedCategory, setSelectedCategory] = useState("");
    const getAllRates = () => {
        setStock();
        setLoader(<Loader />);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product-stock?product_id=${props.product && props.product.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setStock(res.data.product_stock);
                    setWharehouseminStock(res.data.product_stock.minimum_stock);
                    setWharehouseStock(res.data.product_stock.stock);
                    setWharehouseLocation(res.data.product_stock.location);
                    setIsOpenModal(false);
                    setError("");
                    // props.getSingleProduct();
                } else {
                    setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getAllRates();
    }, []);

    const createStock = (e, value) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("product_id", proID);
        formData.append("stock", value.stock);
        formData.append("virtual_stock", value.virtual_stock);
        formData.append("minimum_stock", value.minimum_stock);
        formData.append("location", value.location);
        formData.append("warehouse", value.warehouse);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product-stock`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    getAllRates();
                    setIsOpenModalStock(false);
                    props.setMainFormDisabled(false);
                    setIsOpenModal(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const StockValue = (e) => {
        setStockVal(e.target.value);
    };

    return (
        <>
            <div className="table-responsive">
                <div
                    className="float-end d-none d-md-block btn btn-primary mb-2"
                    onClick={() => {
                        setIsOpenModalStock(true), props.setMainFormDisabled(true);
                    }}
                >
                    {props.t("New")}
                </div>
                <Table striped className="align-middle">
                    <thead className="table-light">
                        <tr>
                            <th>{props.t("Wharehouse")}</th>
                            <th>{props.t("Stock")}</th>
                            <th>{props.t("Virtual Stock")}</th>
                            <th>{props.t("Minimum Stock")}</th>
                            <th>{props.t("Location")}</th>
                            <th>{props.t("Activity")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stock &&
                            stock.map((item, i) => (
                                <SingleStockData
                                    key={i}
                                    data={item}
                                    setIsOpenModal={setIsOpenModal}
                                    getSingleProduct={props.getSingleProduct}
                                    getStock={props.product}
                                    getAllRates={getAllRates}
                                    setMainFormDisabled={props.setMainFormDisabled}
                                />
                            ))}
                    </tbody>
                </Table>

                {error ? <Alert color="danger">{error}</Alert> : ""}
                {loader}
            </div>
            <Modal size="lg" isOpen={isOpenModalStock} centered={true}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.t("Create Stock")}
                    </h5>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e, v) => createStock(e, v)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Warehouse")}
                                        type="text"
                                        name="warehouse"
                                    />
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Stock")}
                                        type="number"
                                        name="stock"
                                        onChange={(e) => StockValue(e)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Minimum Stock")}
                                        type="number"
                                        name="minimum_stock"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/* <div className="mb-3">
                  <AvField label="Product" type="text" readOnly name="name" />
                </div> */}
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Virtual Stock")}
                                        type="number"
                                        readOnly
                                        value={stockVal}
                                        name="virtual_stock"
                                    />
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Location")}
                                        type="text"
                                        name="location"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-end">
                                <button className="btn btn-primary" type="submit">
                                    {props.t("Submit")}
                                </button>
                                <button
                                    className="btn btn-primary ms-2"
                                    type="button"
                                    onClick={() => {
                                        setIsOpenModalStock(false),
                                            props.setMainFormDisabled(false);
                                    }}
                                >
                                    {props.t("Cancel")}
                                </button>
                            </div>
                        </div>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
}
export default withTranslation()(Wharehouse);
Wharehouse.propTypes = {
    t: PropTypes.any,
};
