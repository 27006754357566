import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Alert, Card, CardBody, Col, Row, Table } from "reactstrap";
import { getCurrentUser, getPermission } from "../../../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../../../components/Loader";
import ConsumptionList from "./consumptionList";
import ConsumptionModal from "./consumptionModal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../../plans/trialModal";
import { Link } from "react-router-dom";

const ConsumptionTax = (props) => {
    const [taxModal, setTaxModal] = useState(false);
    const [tax, setTax] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !== 1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id == null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setTaxModal(true);
        }
    };
    const getTaxes = () => {
        setLoader(<Loader />);
        setTax([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/cosumption-taxes`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setTax(res.data.taxes);
                    setLoader("");
                } else {
                    setError(res.data.message);
                    setLoader("");
                }
            });
    };

    useEffect(() => {
        getTaxes();
    }, []);

    return (
        <React.Fragment>
            {taxModal ? (
                <ConsumptionModal
                    taxModal={taxModal}
                    setTaxModal={setTaxModal}
                    getTaxes={getTaxes}
                />
            ) : (
                ""
            )}
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            {/* <div className="page-content"> */}
            <MetaTags>
                <title>Tax | Clouget</title>
            </MetaTags>
            {/* <div className="company_list"> */}
            <div className="page-title-box">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h6 className="page-title">{props.t("Consumption Tax")}</h6>
                    </Col>
                    <Col md={6}>
                        <div className="float-end">
                            {getPermission()?.taxes.taxes.edit.is_checked === 1 ? (
                                <button
                                    onClick={() => createNew()}
                                    className="btn btn-primary ms-2"
                                >
                                    {props.t("Add New")}
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        {/* <Link to="/company" className="btn btn-primary float-end">
                  {props.t("Back")}
                </Link> */}
                    </Col>
                </Row>
            </div>
            <Card>
                <CardBody>
                    <div className="overflow-auto w-100">
                        <Table striped className="align-middle">
                            <thead>
                                <tr>
                                    <th>{props.t("Name")}</th>
                                    <th>{props.t("Tax (in %)")}</th>
                                    <th>{props.t("By Default")}</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>{props.t("Action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tax &&
                                    tax.map((item, i) => (
                                        <ConsumptionList data={item} key={i} i={i} getTaxes={getTaxes} />
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                    {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                    {loader}
                </CardBody>
            </Card>
            {/* </div>
      </div> */}
        </React.Fragment>
    );
};
export default withTranslation()(ConsumptionTax);
ConsumptionTax.propTypes = {
    t: PropTypes.any,
};
