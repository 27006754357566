import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
// Charts
// import "chartist/dist/scss/chartist.scss";
//i18n
import { withTranslation } from "react-i18next";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import { getCurrentUser } from "../../helpers/Utils";
import CreateTrialModal from "../plans/trialModal";
import sales from "../../assets/images/sales.png";
import technicals from "../../assets/images/technical service.png";
import invoices from "../../assets/images/invoices.png";
import purchases from "../../assets/images/purchases.png";
const Dashboard = (props) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [recent, setRecent] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [sort, setSort] = useState(false);
    const [sort2, setSort2] = useState(false);
    const [sort3, setSort3] = useState(false);
    const [sort4, setSort4] = useState(false);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);

    const getDashboardData = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/dashboard-counts`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setIsTrialModal(true);
                    // setIsExpireModal(true);
                    setData(res.data.data);
                } else {
                    setError(res.data.message);
                }
                getCustomStates()
            });
    };

    function createXmlPayload() {
        const xmlDoc = document.implementation.createDocument(null, "root");
        const rootElement = xmlDoc.documentElement;

        const childElement1 = xmlDoc.createElement("childElement1");
        childElement1.textContent = "Value1";
        rootElement.appendChild(childElement1);

        const childElement2 = xmlDoc.createElement("childElement2");
        childElement2.textContent = "Value2";
        rootElement.appendChild(childElement2);

        return new XMLSerializer().serializeToString(xmlDoc);
    }

    // console.log("createXmlPayload->", createXmlPayload());

    const getCustomStates = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/custom_state_types`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    localStorage.setItem("CustomStates", JSON.stringify(res.data.types))
                } else {
                }
            });
    };

    const getLatestTransations = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/dashboard-counts?type=recent&order=date,DESC`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setRecent(res.data.data);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getSearchedList = (e, type) => {
        setError("");
        if (type == "sort") {
            localStorage.setItem("listSort", e);
        }
        // setSearchedCount("");
        // setAllLeads([]);
        setRecent([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/dashboard-counts?type=recent&order=${localStorage.getItem("listSort") !== null
                    ? localStorage.getItem("listSort")
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLoader("");
                    setRecent(res.data.data);
                    //   setCounter(res.data.data.from);
                    //   setAllLeads(res.data.data.data);
                    //   setSearchedCount(res.data.data.total);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getClassName = ($status) => {
        var status_class = "";
        switch ($status) {
            case "pending":
                status_class = "badge bg-warning";
                break;
            case "unpaid":
                status_class = "badge bg-secondary text-dark";
                break;
            case "refused":
                status_class = "badge bg-danger";
                break;
            case "resolved":
                status_class = "badge bg-success";
                break;
            case "paid":
                status_class = "badge bg-success";
                break;
            case "closed":
                status_class = "badge bg-success";
                break;
            default:
                status_class = "badge bg-warning";
        }
        return status_class;
    };

    // const"mini-stat h-100 headerDash"> {
    //   var name_class = "";
    //   switch ($name) {
    //     case "SALES":
    //       name_class = "mini-stat firstBox h-100 headerDash";
    //       break;
    //     case "TECHNICAL SERVICE":
    //       name_class = "mini-stat secondBox h-100 headerDash";
    //       break;
    //     case "INVOICING":
    //       name_class = "mini-stat thirdBox h-100 headerDash";
    //       break;
    //     case "PURCHASES":
    //       name_class = "mini-stat fourthBox h-100 headerDash";
    //       break;
    //     default:
    //       name_class = "mini-stat bg-primary h-100 headerDash";
    //   }
    //   return name_class;
    // };

    const getEntryType = ($reference, $link = "no") => {
        if ($reference == "SE" || $reference == "se") {
            if ($link == "no") {
                return "Sales Estimate";
            }
            return "/sales/";
        }
        if ($reference == "SO" || $reference == "so") {
            if ($link == "no") {
                return "Sales Order";
            }
            return "/sales/";
        }
        if ($reference == "SDN" || $reference == "sdn") {
            if ($link == "no") {
                return "Sales Delivery Note";
            }
            return "/sales/";
        }
        if ($reference == "WE" || $reference == "we") {
            if ($link == "no") {
                return "Work Estimates";
            }
            return "/technical-service/";
        }
        if ($reference == "WO" || $reference == "wo") {
            if ($link == "no") {
                return "Work Order";
            }
            return "/technical-service/";
        }

        if ($reference == "WDN" || $reference == "wdn") {
            if ($link == "no") {
                return "Work Delivery Note";
            }
            return "/technical-service/";
        }

        if ($reference == "INV" || $reference == "inv") {
            if ($link == "no") {
                return "Normal Invoice";
            }
            return "/invoicing-service/";
        }

        if ($reference == "RET" || $reference == "ret" || $reference == "RCT") {
            if ($link == "no") {
                return "Refund Invoice";
            }
            return "/invoicing-service/";
        }

        if ($reference == "PDN" || $reference == "pdn") {
            if ($link == "no") {
                return "Purchase Delivery Note";
            }
            return "/purchase-delivery-Notes/";
        }

        if ($reference == "PO" || $reference == "po") {
            if ($link == "no") {
                return "Purchase Order";
            }
            return "/purchase-order/";
        }

        if ($reference == "PINV" || $reference == "pinv") {
            if ($link == "no") {
                return "Purchase Invoice";
            }
            return "/purchase-invoice/";
        }
    };

    const userLang = () => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users/update-language`,
                {
                    language: localStorage.getItem("I18N_LANGUAGE"),
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) { });
    };

    useEffect(() => {
        getDashboardData();
        getLatestTransations();
        userLang();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                {nonActive ? (
                    <CreateTrialModal
                        isTrialModal={isTrialModal}
                        setIsTrialModal={setIsTrialModal}
                        nonActive={nonActive}
                    />
                ) : (
                    ""
                )}{" "}
                {/* {expire ? (
          <CreateExpireModal
            isExpireModal={isExpireModal}
            setIsExpireModal={setIsExpireModal}
            expire={expire}
          />
        ) : (
          ""
        )}{" "} */}
                <MetaTags>
                    <title>Dashboard</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <h6 className="page-title">{props.t("Dashboard")}</h6>
                    </div>
                    <Row className="mb-5">
                        {data &&
                            data.map((item, i) =>
                                localStorage.getItem("tech_module") !== "1" &&
                                    item.name == "TECHNICAL SERVICE" ? (
                                    ""
                                ) : (
                                    <Col
                                        xl={localStorage.getItem("tech_module") !== "1" ? 4 : 3}
                                        md={6}
                                        key={i}
                                        className="header-title"
                                    >
                                        <Card className="mini-stat h-100 headerDash">
                                            <CardBody className="pb-0">
                                                <div className="mb-2">
                                                    {/* <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div> */}
                                                    <h5
                                                        className="font-size-20 text-uppercase mt-0 text-black-100 headerFontSize"
                                                        style={{ fontFamily: "DejaVu Sans Book" }}
                                                    >
                                                        {props.t(item.name)}
                                                        <img
                                                            src={
                                                                item.name == "SALES"
                                                                    ? sales
                                                                    : item.name == "TECHNICAL SERVICE"
                                                                        ? technicals
                                                                        : item.name == "INVOICING"
                                                                            ? invoices
                                                                            : purchases
                                                            }
                                                            className="float-end"
                                                            width="30px"
                                                            height="30px"
                                                        />
                                                    </h5>
                                                </div>
                                                {item.data &&
                                                    item.data.map((option, index) => (
                                                        <div className="pt-2" key={index}>
                                                            <div className="float-end">
                                                                <Link
                                                                    to={option.link}
                                                                    className="typeDash"
                                                                // style={{
                                                                //   fontFamily: "DejaVu Sans Condensed",
                                                                // }}
                                                                >
                                                                    {props.t(option.value)}
                                                                </Link>
                                                            </div>
                                                            <Link
                                                                to={props.t(option.link)}
                                                                className="typeDash mb-0 mt-1"
                                                                style={{
                                                                    fontFamily: "DejaVu Sans Condensed",
                                                                }}
                                                            >
                                                                {item.name == "TECHNICAL SERVICE" &&
                                                                    option.lable == "Orders" &&
                                                                    localStorage.getItem("I18N_LANGUAGE") == "sp"
                                                                    ? "Órdenes de trabajo"
                                                                    : item.name == "TECHNICAL SERVICE" &&
                                                                        option.lable == "Delivery Notes" &&
                                                                        localStorage.getItem("I18N_LANGUAGE") ==
                                                                        "sp"
                                                                        ? "Notas de trabajo"
                                                                        : item.name == "PURCHASES" &&
                                                                            option.lable == "Orders" &&
                                                                            localStorage.getItem("I18N_LANGUAGE") ==
                                                                            "sp"
                                                                            ? "Pedidos de proveedor"
                                                                            : item.name == "PURCHASES" &&
                                                                                option.lable == "Delivery Notes" &&
                                                                                localStorage.getItem("I18N_LANGUAGE") ==
                                                                                "sp"
                                                                                ? "Notas de entrega de proveedor"
                                                                                : item.name == "PURCHASES" &&
                                                                                    option.lable == "Invoices" &&
                                                                                    localStorage.getItem("I18N_LANGUAGE") ==
                                                                                    "sp"
                                                                                    ? "Facturas de proveedor"
                                                                                    : `${props.t(option.lable)}`}
                                                            </Link>
                                                        </div>
                                                    ))}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            )}
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                {/* <Card style={{ height: "40", overflowY: "scroll" }}> */}
                                <CardBody>
                                    <h4 className="card-title mb-4">
                                        {props.t("Latest Transaction")}
                                    </h4>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <div
                                                            className="position-relative"
                                                            style={{ whiteSpace: "nowrap" }}
                                                        >
                                                            {props.t("Reference")} &nbsp;
                                                            {/* <i className="fa fa-sort" role="button" /> */}
                                                            {sort ? (
                                                                <>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => {
                                                                            setSort2(true),
                                                                                setSort(false),
                                                                                setSort3(false),
                                                                                setSort4(false),
                                                                                getSearchedList(
                                                                                    "reference,DESC",
                                                                                    "sort"
                                                                                );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-sort-up ms-2"></i>
                                                                    </span>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setSort2(true),
                                                                                setSort(false),
                                                                                setSort3(false),
                                                                                setSort4(false),
                                                                                getSearchedList(
                                                                                    "reference,DESC",
                                                                                    "sort"
                                                                                );
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-sort-down"
                                                                            style={{
                                                                                position: "absolute",
                                                                                left: "0",
                                                                                bottom: "0",
                                                                                transform: "translate(753%, -3px)",
                                                                            }}
                                                                        ></i>
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setSort(true),
                                                                                setSort2(false),
                                                                                setSort3(false),
                                                                                setSort4(false),
                                                                                getSearchedList(
                                                                                    "reference,ASC",
                                                                                    "sort"
                                                                                );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-sort-up ms-2"></i>
                                                                    </span>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setSort2(true),
                                                                                setSort(false),
                                                                                setSort3(false),
                                                                                setSort4(false),
                                                                                getSearchedList(
                                                                                    "reference,DESC",
                                                                                    "sort"
                                                                                );
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-sort-down"
                                                                            style={{
                                                                                position: "absolute",
                                                                                left: "0",
                                                                                bottom: "0",
                                                                                transform: "translate(753%, -3px)",
                                                                            }}
                                                                        ></i>
                                                                    </span>
                                                                </>
                                                            )}
                                                            {sort2 ? (
                                                                <>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => {
                                                                            setSort2(false),
                                                                                setSort(true),
                                                                                setSort3(false),
                                                                                setSort4(false),
                                                                                getSearchedList(
                                                                                    "reference,ASC",
                                                                                    "sort"
                                                                                );
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-sort-down"
                                                                            style={{
                                                                                position: "absolute",
                                                                                left: "0",
                                                                                bottom: "0",
                                                                                transform: "translate(753%, -3px)",
                                                                            }}
                                                                        ></i>
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col">{props.t("Client")}</th>
                                                    <th scope="col">{props.t("Title")}</th>
                                                    <th scope="col">{props.t("Status")}</th>
                                                    <th scope="col">{props.t("Type")}</th>
                                                    <th scope="col">
                                                        <div
                                                            className="position-relative"
                                                            style={{ whiteSpace: "nowrap" }}
                                                        >
                                                            {props.t("Date")} &nbsp;
                                                            {/* <i className="fa fa-sort" role="button" /> */}
                                                            {sort3 ? (
                                                                <>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => {
                                                                            setSort4(true),
                                                                                setSort3(false),
                                                                                setSort(false),
                                                                                setSort2(false),
                                                                                getSearchedList("date,DESC", "sort");
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-sort-up ms-2"></i>
                                                                    </span>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setSort4(true),
                                                                                setSort3(false),
                                                                                setSort(false),
                                                                                setSort2(false),
                                                                                getSearchedList("date,DESC", "sort");
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-sort-down"
                                                                            style={{
                                                                                position: "absolute",
                                                                                left: "0",
                                                                                bottom: "0",
                                                                                transform: "translate(423%, -3px)",
                                                                            }}
                                                                        ></i>
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setSort3(true),
                                                                                setSort4(false),
                                                                                setSort(false),
                                                                                setSort2(false),
                                                                                getSearchedList("date,ASC", "sort");
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-sort-up ms-2"></i>
                                                                    </span>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setSort4(true),
                                                                                setSort3(false),
                                                                                setSort(false),
                                                                                setSort2(false),
                                                                                getSearchedList("date,DESC", "sort");
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-sort-down"
                                                                            style={{
                                                                                position: "absolute",
                                                                                left: "0",
                                                                                bottom: "0",
                                                                                transform: "translate(423%, -3px)",
                                                                            }}
                                                                        ></i>
                                                                    </span>
                                                                </>
                                                            )}
                                                            {sort4 ? (
                                                                <>
                                                                    <span
                                                                        className="fs-5"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => {
                                                                            setSort4(false),
                                                                                setSort3(true),
                                                                                setSort(false),
                                                                                setSort2(false),
                                                                                getSearchedList("date,ASC", "sort");
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-sort-down"
                                                                            style={{
                                                                                position: "absolute",
                                                                                left: "0",
                                                                                bottom: "0",
                                                                                transform: "translate(423%, -3px)",
                                                                            }}
                                                                        ></i>
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col">{props.t("Amount")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recent &&
                                                    recent.map((item, i) => (
                                                        <tr
                                                            style={{ cursor: "pointer" }}
                                                            key={i}
                                                            className="transaction_row"
                                                            onClick={() =>
                                                                history.push(
                                                                    getEntryType(item.reference, "yes") + item.id
                                                                )
                                                            }
                                                        >
                                                            <th scope="row">
                                                                {item.reference + item.reference_number}
                                                            </th>
                                                            <td className="tableContent">
                                                                {item.client_name
                                                                    ? item.client_name
                                                                    : item.supplier_name}
                                                            </td>
                                                            <td>{item.title}</td>
                                                            <td>
                                                                <span className={getClassName(item.status)}>
                                                                    {props.t(
                                                                        item.status == "pending"
                                                                            ? "Pending"
                                                                            : item.status == "pending invoice"
                                                                                ? "Pending Invoice"
                                                                                : item.status == "unpaid"
                                                                                    ? "Unpaid"
                                                                                    : item.status == "paid"
                                                                                        ? "Paid"
                                                                                        : item.status == "closed"
                                                                                            ? "Closed"
                                                                                            : item.status == "refused"
                                                                                                ? "Refused"
                                                                                                : item.status == " resolved"
                                                                                                    ? "Resolved"
                                                                                                    : item.status == "accepted"
                                                                                                        ? "Accepted"
                                                                                                        : item.status == "in_progress"
                                                                                                            ? "In Progress"
                                                                                                            : item.status == "partially paid"
                                                                                                                ? "Partially Paid"
                                                                                                                : item.status
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>{props.t(getEntryType(item.reference))}</td>
                                                            <td>{item.date}</td>
                                                            <td>${item.amount}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col xl={4}>
              <Card>
                <CardBody>
                  <div>
                    <h4 className="card-title mb-4">Sales Analytics</h4>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Online</p>
                          <h5 className="mb-4">1,542</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Offline</p>
                          <h5 className="mb-4">6,451</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine1 />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Marketing</p>
                          <h5>84,574</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

Dashboard.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Dashboard);
