import React, { useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
const axios = require("axios").default;
import { Col, Row, Modal, Alert } from "reactstrap";
import { getCurrentUser } from "../../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function IncomeModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const submitTax = (e, value) => {
        let byDef = "0";
        if (value.by_default) {
            byDef = "1";
        }
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/income-taxes${props.tax !== undefined ? `/${props.tax.id}?_method=PUT` : ""
                }`,
                {
                    name: value.name,
                    tax: value.tax,
                    by_default: byDef,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    setLoading(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                    setLoading(false);
                }
                setTimeout(() => {
                    // if (props.tax !== undefined) {
                    //     props.setTax(res.data.tax);
                    // } else {
                    // }
                    props.getTaxes();
                    props.setTaxModal(false);
                    setError("");
                    setSuccess("");
                }, 3500);
            });
    };

    return (
        <Modal isOpen={props.taxModal} size="lg" centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    submitTax(e, v);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.tax !== undefined
                            ? props.tax.name
                            : `${props.t("New Income Tax")}`}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setTaxModal(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? (
                        <div className="mb-3">
                            <Alert color="success">{props.t(success)}</Alert>
                        </div>
                    ) : null}

                    {error ? (
                        <div className="mb-3">
                            <Alert color="danger">{props.t(error)}</Alert>
                        </div>
                    ) : null}
                    <Row>
                        <Col md={12}>
                            <div className="mb-3 required">
                                <AvField
                                    label={props.t("Name")}
                                    type="text"
                                    name="name"
                                    value={props.tax && props.tax.name}
                                    required="required"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3 required">
                                <AvField
                                    label={props.t("Tax (in %)")}
                                    type="number"
                                    name="tax"
                                    min="0"
                                    max="100"
                                    value={props.tax && props.tax.tax}
                                    required="required"
                                    errorMessage="Enter value between 0-100"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <label>{props.t("By default")}</label>
                            <div className=" mb-3">
                                <AvField
                                    type="checkbox"
                                    name="by_default"
                                    defaultChecked={
                                        props.tax && props.tax.by_default == "1" ? true : false
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                            >
                                {props.t("Loading")}
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="submit">
                                {props.bankId ? `${props.t("Update")}` : `${props.t("Submit")}`}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setTaxModal(false)}
                        >
                            {props.t("Cancel")}
                        </button>
                    </div>
                </div>
            </AvForm>
        </Modal>
    );
}
export default withTranslation()(IncomeModal);
IncomeModal.propTypes = {
    t: PropTypes.any,
};
